.query-metadata .query-metadata-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
}
.query-metadata .query-metadata-item img {
  margin: 0 5px 0 0;
}
.query-metadata .query-metadata-item .query-metadata-property {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.query-metadata .query-metadata-item .query-metadata-property .query-metadata-label {
  margin: 0 5px 0 0;
}
.query-metadata .query-metadata-item .query-metadata-property .query-metadata-label:only-child {
  margin-right: 0;
}
.query-metadata .query-metadata-item .query-metadata-property .query-metadata-value {
  margin: 0;
}
.query-metadata .query-metadata-space {
  display: none;
}
.query-metadata.query-metadata-table {
  padding: 15px;
  border-top: 1px solid #efefef;
}
.query-metadata.query-metadata-table .query-metadata-item {
  margin-bottom: 8px;
}
.query-metadata.query-metadata-table .query-metadata-item:last-child {
  margin-top: 20px;
  margin-bottom: 0;
}
.query-metadata.query-metadata-table .query-metadata-item .query-metadata-property {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.query-metadata.query-metadata-horizontal {
  padding: 5px 0;
  margin: 0 -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 500px) {
  .query-metadata.query-metadata-horizontal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: stretch;
        -ms-flex-pack: stretch;
            justify-content: stretch;
  }
}
@media (min-width: 1000px) {
  .query-metadata.query-metadata-horizontal {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .query-metadata.query-metadata-horizontal .query-metadata-space {
    display: block;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    text-align: right;
  }
}
.query-metadata.query-metadata-horizontal .query-metadata-item {
  padding: 5px;
}
.query-metadata.query-metadata-horizontal .query-metadata-item:last-child .query-metadata-property .query-metadata-label {
  white-space: nowrap;
}
.query-metadata.query-metadata-horizontal .query-metadata-item:last-child .query-metadata-property .query-metadata-label:after {
  content: ":";
}
.query-metadata.query-metadata-horizontal .query-metadata-item .query-metadata-property .query-metadata-label .zmdi {
  display: none;
}
.query-metadata.query-metadata-horizontal .query-metadata-item .query-metadata-property .query-metadata-value strong {
  font-weight: normal;
}
