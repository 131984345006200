.query-editor-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
}
.query-editor-controls span.query-editor-controls-button {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
}
.query-editor-controls .ant-btn {
  height: auto;
}
.query-editor-controls .ant-btn .fa + span,
.query-editor-controls .ant-btn .zmdi + span {
  margin-left: 5px;
}
.query-editor-controls .query-editor-controls-checkbox {
  display: inline-block;
  white-space: nowrap;
  margin: auto 5px;
}
.query-editor-controls .query-editor-controls-spacer {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  height: 35px;
}
