@media (min-width: 992px) {
  .edit-visualization-dialog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
  }
  .edit-visualization-dialog .visualization-settings {
    padding-right: 12px;
    width: 40%;
    overflow: auto;
  }
  .edit-visualization-dialog .visualization-preview {
    padding-left: 12px;
    width: 60%;
    overflow: auto;
  }
}
