.parameter-block {
  display: inline-block;
  background: white;
  padding: 0 12px 6px 0;
  vertical-align: top;
  z-index: 1;
  white-space: nowrap;
}
.parameter-block .drag-handle {
  padding: 0 5px;
  margin-left: -5px;
  height: 36px;
}
.parameter-container.sortable-container .parameter-block {
  margin: 4px 0 0 4px;
  padding: 3px 6px 6px;
}
.parameter-block.parameter-dragged {
  z-index: 2;
  margin: 4px 0 0 4px;
  padding: 3px 6px 6px;
  -webkit-box-shadow: 0 4px 9px -3px rgba(102, 136, 153, 0.15);
          box-shadow: 0 4px 9px -3px rgba(102, 136, 153, 0.15);
}
.parameter-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 4px;
}
.parameter-heading label {
  margin-bottom: 1px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  min-width: 100%;
  max-width: 195px;
  white-space: nowrap;
}
.parameter-block[data-editable] .parameter-heading label {
  min-width: calc(100% - 27px);
  max-width: 168px;
}
.parameter-container {
  position: relative;
}
.parameter-container.sortable-container {
  padding: 0 4px 4px 0;
}
.parameter-container .parameter-apply-button {
  display: none;
}
@media (min-width: 768px) {
  .parameter-container .parameter-apply-button {
    position: absolute;
    bottom: -36px;
    left: -15px;
    border-radius: 2px;
    z-index: 2;
    -webkit-transition: opacity 150ms ease-out;
    -o-transition: opacity 150ms ease-out;
    transition: opacity 150ms ease-out;
    -webkit-box-shadow: 0 4px 9px -3px rgba(102, 136, 153, 0.15);
            box-shadow: 0 4px 9px -3px rgba(102, 136, 153, 0.15);
    background-color: #ffffff;
    padding: 4px;
    padding-left: 16px;
    opacity: 0;
    display: block;
    pointer-events: none;
  }
}
.parameter-container .parameter-apply-button[data-show="true"] {
  opacity: 1;
  display: block;
  pointer-events: auto;
}
.parameter-container .parameter-apply-button button {
  padding: 0 8px 0 6px;
  color: #2096f3;
  border-color: #50acf6;
}
@media (min-width: 768px) {
  .parameter-container .parameter-apply-button button {
    font-size: 12px;
    height: 27px;
  }
}
.parameter-container .parameter-apply-button button:hover,
.parameter-container .parameter-apply-button button:focus,
.parameter-container .parameter-apply-button button:active {
  background-color: #eef7fe;
}
.parameter-container .parameter-apply-button button i {
  margin-right: 3px;
}
.parameter-container .parameter-apply-button .ant-badge-count {
  min-width: 15px;
  height: 15px;
  padding: 0 5px;
  font-size: 10px;
  line-height: 15px;
  background: #f77b74;
  border-radius: 7px;
  -webkit-box-shadow: 0 0 0 1px white, -1px 1px 0 1px #5d6f7d85;
          box-shadow: 0 0 0 1px white, -1px 1px 0 1px #5d6f7d85;
}
