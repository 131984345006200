.layout-with-sidebar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 0;
}
.layout-with-sidebar > .layout-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto;
  width: 75%;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  margin: 0;
  padding: 0 0 0 15px;
}
.layout-with-sidebar > .layout-sidebar {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
  max-width: 350px;
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
  margin: 0;
}
@media (max-width: 990px) {
  .layout-with-sidebar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .layout-with-sidebar > .layout-content {
    width: 100%;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin: 0;
    padding: 0;
  }
  .layout-with-sidebar > .layout-sidebar {
    width: 100%;
    max-width: none;
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0;
    margin: 0 0 15px 0;
  }
}
