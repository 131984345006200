.dynamic-button {
  height: 100%;
  position: absolute !important;
  right: 1px;
  top: 0;
}
.dynamic-button .ant-dropdown-trigger {
  height: 100%;
}
.dynamic-button button {
  border: none;
  padding: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent !important;
}
.dynamic-button:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 19px;
  left: 0;
  top: 8px;
  border-left: 1px dotted rgba(0, 0, 0, 0.12);
}
.dynamic-menu {
  width: 187px;
}
.dynamic-menu em {
  color: #ccc;
  font-size: 11px;
}
.dynamic-icon {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
