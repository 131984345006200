.help-trigger {
  font-size: 15px;
}
.help-trigger:hover {
  cursor: pointer;
}
.help-drawer .ant-drawer-body {
  padding: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.help-drawer .drawer-wrapper {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.help-drawer .drawer-menu {
  position: fixed;
  z-index: 1;
  top: 13px;
  right: 13px;
  border-radius: 3px;
  background: rgba(247, 247, 247, 0.75);
  border: 2px solid #f7f7f7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.help-drawer .drawer-menu a,
.help-drawer .drawer-menu .plain-button {
  height: 26px;
  width: 26px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: rgba(0, 0, 0, 0.45);
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
  position: relative;
  cursor: pointer;
}
.help-drawer .drawer-menu a:hover,
.help-drawer .drawer-menu .plain-button:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.help-drawer .drawer-menu a .anticon,
.help-drawer .drawer-menu .plain-button .anticon {
  font-size: 15px;
}
.help-drawer .drawer-menu a .fa-external-link,
.help-drawer .drawer-menu .plain-button .fa-external-link {
  position: relative;
  top: 1px;
  font-size: 14px;
}
.help-drawer .drawer-menu a:not(:first-child):before,
.help-drawer .drawer-menu .plain-button:not(:first-child):before {
  content: "";
  position: absolute;
  width: 1px;
  height: 9px;
  left: 0;
  top: 9px;
  border-left: 1px dotted rgba(0, 0, 0, 0.12);
}
.help-drawer iframe {
  width: 0;
  visibility: hidden;
}
.help-drawer iframe.ready {
  border: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
}
