.alert-template {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.alert-template input {
  margin-bottom: 10px;
}
.alert-template textarea {
  margin-bottom: 0 !important;
}
.alert-template input,
.alert-template textarea {
  font-family: "Roboto Mono", monospace;
  font-size: 12px;
  letter-spacing: -0.4px;
}
.alert-template input[disabled],
.alert-template textarea[disabled] {
  color: inherit;
  cursor: auto;
}
.alert-template .alert-custom-template {
  margin-top: 10px;
  padding: 4px 10px 2px;
  background: #fbfbfb;
  border: 1px dashed #d9d9d9;
  border-radius: 3px;
  max-width: 500px;
}
.alert-template .alert-template-preview {
  margin: 0 0 0 5px !important;
}
