.query-visualization-tabs .query-results-empty-state {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 15px;
}
.query-visualization-tabs .query-results-empty-state .empty-state-content {
  max-width: 280px;
  text-align: center;
}
.query-visualization-tabs .query-results-empty-state img {
  max-width: 100%;
}
.query-visualization-tabs .ant-tabs-nav-wrap,
.query-visualization-tabs .ant-tabs-extra-content {
  -webkit-box-flex: initial !important;
      -ms-flex: initial !important;
          flex: initial !important;
}
.query-visualization-tabs .ant-tabs-nav-wrap {
  z-index: 1;
}
.query-visualization-tabs .ant-tabs-tab {
  background: #f6f8f9 !important;
  border-color: #d9d9d9 !important;
  border-bottom: 0px !important;
  border-radius: 0 !important;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-width 0s !important;
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-width 0s !important;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-width 0s !important;
}
.query-visualization-tabs .ant-tabs-tab:first-child {
  border-radius: 2px 0 0 0 !important;
}
.query-visualization-tabs .ant-tabs-tab:last-child {
  border-radius: 0 2px 0 0 !important;
}
.query-visualization-tabs .ant-tabs-tab:not(:first-child) {
  margin-left: -1px !important;
}
.query-visualization-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: white !important;
  font-weight: normal;
  border-top: 2px solid #2196f3 !important;
}
.query-visualization-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: normal;
}
.query-visualization-tabs .ant-tabs-tab:not(.ant-tabs-tab-active) {
  -webkit-box-shadow: 0px -1px 0px #d9d9d9 inset;
          box-shadow: 0px -1px 0px #d9d9d9 inset;
}
.query-visualization-tabs .ant-tabs-content-holder {
  margin-top: -17px;
  border: 1px solid #d9d9d9;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 0px 4px 0px 0px;
}
.query-visualization-tabs .ant-tabs-content-holder .ant-tabs-tabpane {
  padding: 16px;
  background: white;
}
.query-visualization-tabs .add-visualization-button span {
  color: #767676;
}
.query-visualization-tabs .delete-visualization-button {
  height: 1.5rem;
  width: 1.5rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: 5px;
  color: #a09797;
  font-size: 11px;
  border-radius: 100%;
}
.query-visualization-tabs .delete-visualization-button:hover,
.query-visualization-tabs .delete-visualization-button:focus {
  color: white;
  background-color: #ff8080;
}
.query-visualization-tabs .delete-visualization-button:active {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}
.ant-tabs-dropdown-menu-item .delete-visualization-button {
  display: none;
}
.query-fixed-layout .query-visualization-tabs .visualization-renderer {
  padding: 15px;
}
