.mobile-navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #001529;
  -webkit-box-shadow: 0 4px 9px -3px rgba(102, 136, 153, 0.15);
          box-shadow: 0 4px 9px -3px rgba(102, 136, 153, 0.15);
  padding: 0 15px;
  height: 100%;
}
.mobile-navbar-logo img {
  height: 40px;
  width: 40px;
}
.mobile-navbar .ant-btn.mobile-navbar-toggle-button {
  padding: 0 10px;
}
.mobile-navbar-menu .ant-dropdown-menu-item {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}
.mobile-navbar-menu .ant-dropdown-menu-item-divider {
  background: rgba(255, 255, 255, 0.5);
}
