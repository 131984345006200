.parameter-input {
  display: inline-block;
  position: relative;
  width: 100%;
}
.parameter-input .ant-input,
.parameter-input .ant-input-number {
  min-width: 100% !important;
}
.parameter-input .ant-select {
  width: 100%;
}
.parameter-input[data-dirty] .ant-input,
.parameter-input[data-dirty] .ant-input-number,
.parameter-input[data-dirty] .ant-select-selector,
.parameter-input[data-dirty] .ant-picker {
  background-color: #fffce1;
}
