.schedule {
  width: 449px !important;
  margin: 0 auto;
}

.schedule-component {
  padding: 5px 0px;
}

.schedule-component > * {
  display: inline-block;
}

.schedule-component h5 {
  margin-right: 10px;
  width: 87px;
  text-align: right;
}

.schedule-component > div > *:not(:last-child) {
  margin-right: 3px;
}

.schedule-component datepicker {
  display: block;
}

.schedule-phrase {
  display: inline-block;
}

a.schedule-phrase {
  cursor: pointer;
}

.utc {
  opacity: 0.4;
  margin-left: 10px;
}