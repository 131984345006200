.alert-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 5px;
}
.alert-header > div {
  padding: 5px 0;
}
.alert-header .alert-title {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
}
.alert-header .alert-title h3 {
  margin: 0 15px 0 0;
}
@media (max-width: 767px) {
  .alert-header .alert-title h3 {
    font-size: 18px;
  }
}
.alert-header .alert-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
@media (max-width: 515px) {
  .alert-header .alert-actions {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}
