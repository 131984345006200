.resizable-component.react-resizable {
  position: relative;
}
.resizable-component.react-resizable .react-resizable-handle {
  position: absolute;
  background: #fff;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.resizable-component.react-resizable .react-resizable-handle:hover,
.resizable-component.react-resizable .react-resizable-handle:active {
  background: #f6f8f9;
}
.resizable-component.react-resizable .react-resizable-handle.react-resizable-handle-horizontal {
  cursor: col-resize;
  width: 10px;
  height: auto;
  right: 0;
  top: 0;
  bottom: 0;
}
.resizable-component.react-resizable .react-resizable-handle.react-resizable-handle-horizontal:before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 25px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.resizable-component.react-resizable .react-resizable-handle.react-resizable-handle-vertical {
  cursor: row-resize;
  width: auto;
  height: 10px;
  left: 0;
  right: 0;
  bottom: 0;
}
.resizable-component.react-resizable .react-resizable-handle.react-resizable-handle-vertical:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 3px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
