.system-status-page {
  overflow: hidden;
  padding: 15px;
}
.system-status-page .system-status-page-blocks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: -7.5px;
}
.system-status-page .system-status-page-blocks .system-status-page-block {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  padding: 7.5px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.system-status-page .system-status-page-blocks .system-status-page-block > div {
  width: 100%;
}
@media (min-width: 768px) {
  .system-status-page .system-status-page-blocks .system-status-page-block {
    width: 50%;
  }
}
@media (min-width: 1600px) {
  .system-status-page .system-status-page-blocks .system-status-page-block {
    width: 25%;
  }
}
.system-status-page .system-status-page-blocks .ant-list-item:first-child {
  padding-top: 0;
}
.system-status-page .system-status-page-blocks .ant-list-item:last-child {
  padding-bottom: 0;
}
.system-status-page .system-status-page-blocks .ant-list-item-content {
  margin: 0;
}
