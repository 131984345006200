.error-message-container {
  width: 100%;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.error-message-container .error-state {
  max-width: 1200px;
  width: 100%;
}
@media (min-width: 768px) {
  .error-message-container .error-state {
    width: 65%;
  }
}
