.code-block {
  background: rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  padding: 3px 27px 3px 3px;
  position: relative;
  min-height: 32px;
}
.code-block code {
  padding: 0;
  font-size: 85%;
}
.code-block .ant-btn {
  position: absolute;
  right: 3px;
  bottom: 3px;
  padding-left: 3px !important;
  padding-right: 3px !important;
}
