body #application-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  padding-bottom: 0 !important;
  height: 100vh;
}
body #application-root .application-layout-side-menu {
  height: 100vh;
  position: relative;
}
@media (max-width: 767px) {
  body #application-root .application-layout-side-menu {
    display: none;
  }
}
body #application-root .application-layout-top-menu {
  height: 49px;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 1000;
}
@media (max-width: 767px) {
  body #application-root .application-layout-top-menu {
    display: block;
  }
}
body #application-root .application-layout-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow-y: auto;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding-bottom: 15px;
}
@media (max-width: 767px) {
  body #application-root .application-layout-content {
    margin-top: 49px;
  }
}
body.fixed-layout #application-root .application-layout-content {
  padding-bottom: 0;
}
body.headless #application-root .application-layout-side-menu,
body.headless #application-root .application-layout-top-menu {
  display: none !important;
}
body.headless #application-root .application-layout-content {
  margin-top: 0;
}
@media only percy {
  body #application-root {
    height: auto;
  }
  body #application-root .application-layout-side-menu {
    height: auto;
  }
  body #application-root .application-layout-content {
    overflow: visible;
  }
}
