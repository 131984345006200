.visual-card-list {
  width: 100%;
  margin: -5px 0 0 -5px;
}
.visual-card {
  background: #ffffff;
  border: 1px solid rgba(102, 136, 153, 0.15);
  border-radius: 3px;
  margin: 5px;
  width: 212px;
  padding: 15px 5px;
  cursor: pointer;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition: -webkit-transform 0.12s ease-out;
  transition: -webkit-transform 0.12s ease-out;
  -o-transition: transform 0.12s ease-out;
  transition: transform 0.12s ease-out;
  transition: transform 0.12s ease-out, -webkit-transform 0.12s ease-out;
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
  -webkit-transition-property: -webkit-box-shadow;
  transition-property: -webkit-box-shadow;
  -o-transition-property: box-shadow;
  transition-property: box-shadow;
  transition-property: box-shadow, -webkit-box-shadow;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.visual-card:hover,
.visual-card:focus,
.visual-card:focus-within {
  -webkit-box-shadow: rgba(102, 136, 153, 0.15) 0px 4px 9px -3px;
          box-shadow: rgba(102, 136, 153, 0.15) 0px 4px 9px -3px;
}
.visual-card img {
  width: 64px !important;
  height: 64px !important;
  margin-right: 5px;
}
.visual-card h3 {
  font-size: 13px;
  color: #323232;
  margin: 0 !important;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .visual-card {
    width: 217px;
  }
}
@media (max-width: 755px) {
  .visual-card {
    width: 47%;
  }
}
@media (max-width: 515px) {
  .visual-card {
    width: 47%;
  }
  .visual-card img {
    width: 48px;
    height: 48px;
  }
}
@media (max-width: 408px) {
  .visual-card {
    width: 100%;
    padding: 5px;
  }
  .visual-card img {
    width: 48px;
    height: 48px;
  }
}
