.query-execution-metadata {
  padding: 10px 15px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.query-execution-metadata button,
.query-execution-metadata div,
.query-execution-metadata span {
  position: relative;
}
.query-execution-metadata div:last-child {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: right;
}
.query-execution-metadata:before {
  content: "";
  height: 50px;
  position: fixed;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  left: 0;
}
