@media (max-width: 767px) {
}.dashboard-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  position: sticky;
  background: #f6f7f9;
  z-index: 99;
  width: 100%;
  top: 0;
  padding-top: 10px;
  margin-bottom: 10px;
}
.dashboard-header > div {
  padding: 5px 0;
}
.dashboard-header .title-with-tags {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: -5px 0;
}
.dashboard-header .title-with-tags > div {
  padding: 5px 0;
}
.dashboard-header .title-with-tags h3 {
  margin: 0;
}
@media (max-width: 767px) {
  .dashboard-header .title-with-tags h3 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .dashboard-header {
    position: static;
  }
}
.dashboard-header .profile-image {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  margin: 3px 5px 0 5px;
}
.dashboard-header .tags-control > .label-tag {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.dashboard-header:hover .tags-control > .label-tag,
.dashboard-header:focus .tags-control > .label-tag,
.dashboard-header:active .tags-control > .label-tag,
.dashboard-header:focus-within .tags-control > .label-tag {
  opacity: 1;
}
.dashboard-header .dashboard-control .icon-button {
  width: 32px;
  padding: 0 10px;
}
.dashboard-header .dashboard-control .save-status {
  vertical-align: middle;
  margin-right: 7px;
  font-size: 12px;
  text-align: left;
  display: inline-block;
}
.dashboard-header .dashboard-control .save-status[data-saving] {
  opacity: 0.6;
  width: 45px;
}
.dashboard-header .dashboard-control .save-status[data-saving]:after {
  content: "";
  -webkit-animation: saving 2s linear infinite;
          animation: saving 2s linear infinite;
}
.dashboard-header .dashboard-control .save-status[data-error] {
  color: #f44336;
}
@media (max-width: 515px) {
  .dashboard-header .dashboard-control {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}
@-webkit-keyframes saving {
  0%,
  100% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}
@keyframes saving {
  0%,
  100% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}
