.date-range-parameter,
.date-parameter {
  position: relative;
}
.redash-datepicker {
  padding-right: 35px !important;
}
.redash-datepicker.date-range {
  width: 294px;
}
.redash-datepicker.datetime-range {
  width: 352px;
}
.redash-datepicker.datetime-range-with-seconds {
  width: 382px;
}
.redash-datepicker.dynamic-value {
  width: 195px;
}
.redash-datepicker.ant-picker-range .ant-picker-clear {
  right: 35px !important;
  background: transparent;
}
.redash-datepicker.date-range-input {
  -webkit-transition: width 100ms ease-in-out;
  -o-transition: width 100ms ease-in-out;
  transition: width 100ms ease-in-out;
}
.redash-datepicker.dynamic-value ::-webkit-input-placeholder {
  color: #595959 !important;
}
.redash-datepicker.dynamic-value ::-moz-placeholder {
  color: #595959 !important;
}
.redash-datepicker.dynamic-value ::-ms-input-placeholder {
  color: #595959 !important;
}
.redash-datepicker.dynamic-value ::placeholder {
  color: #595959 !important;
}
.redash-datepicker.dynamic-value.date-range-input .ant-picker-active-bar {
  opacity: 0;
}
.redash-datepicker.dynamic-value.date-range-input .ant-picker-separator,
.redash-datepicker.dynamic-value.date-range-input .ant-picker-range-separator {
  display: none;
}
.redash-datepicker.dynamic-value.date-range-input .ant-picker-input:not(:first-child) {
  width: 0;
}
