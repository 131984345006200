/****
  grid bg - based on 6 cols, 35px rows and 15px spacing
****/
.dashboard-page,
.dashboard-page .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
#dashboard-container {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.add-widget-container {
  background: #fff;
  border-radius: 3px;
  padding: 15px;
  position: fixed;
  bottom: 20px;
  z-index: 99;
  -webkit-box-shadow: rgba(102, 136, 153, 0.5) 0px 7px 29px -3px;
          box-shadow: rgba(102, 136, 153, 0.5) 0px 7px 29px -3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.add-widget-container h2 {
  margin: 0;
  font-size: 14px;
  line-height: 2.1;
  font-weight: 400;
}
.add-widget-container h2 .zmdi {
  margin: 0;
  margin-right: 5px;
  font-size: 24px;
  position: absolute;
  bottom: 18px;
}
.add-widget-container h2 span {
  vertical-align: middle;
  padding-left: 30px;
}
.add-widget-container .btn {
  -ms-flex-item-align: center;
      align-self: center;
}
