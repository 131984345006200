.alert-query-link {
  font-size: 14px;
}
.alert-query-link .fa-external-link {
  vertical-align: text-bottom;
}
.alert-query-schedule {
  font-style: italic;
  text-transform: lowercase;
}
@media only percy {
  .alert-query-selector .ant-select-arrow-icon {
    display: none !important;
  }
}
