/* Prevent text selection on shift+click. */
div.tags-list {
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

/* same rule as for sidebar */
@media (max-width: 990px) {
  .page-dashboard-list .page-header-actions {
    width: auto;
  }
}
