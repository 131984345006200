.alert-criteria {
  margin-top: 40px !important;
}
.alert-criteria .input-title {
  display: inline-block;
  width: auto;
  margin-right: 8px;
  margin-bottom: 17px;
  position: relative;
  vertical-align: middle;
}
.alert-criteria .input-title > .input-label {
  position: absolute;
  top: -16px;
  left: 0;
  line-height: normal;
  font-size: 10px;
}
.alert-criteria .input-title > .input-label + * {
  vertical-align: top;
}
.alert-criteria .ant-form-item-explain {
  margin-top: -17px;
}
.alert-criteria .alert-criteria-hint code {
  overflow: hidden;
  max-width: 100px;
  display: inline-block;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.criteria-disabled-input {
  text-align: center;
  line-height: 35px;
  height: 35px;
  max-width: 200px;
  background: #ececec;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-bottom: 5px;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 8px;
}
