@media screen and (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (min-width: 768px) {
}@media (min-width: 768px) {
}@media (max-width: 767px) {
}@media (max-width: 767px) {
}@media (min-width: 768px) {
}.ant-list.add-to-dashboard-dialog-search-results {
  margin-top: 15px;
}
.ant-list.add-to-dashboard-dialog-search-results .ant-list-items {
  max-height: 300px;
  overflow: auto;
}
.ant-list.add-to-dashboard-dialog-search-results .ant-list-item {
  padding: 12px;
  cursor: pointer;
}
.ant-list.add-to-dashboard-dialog-search-results .ant-list-item:hover,
.ant-list.add-to-dashboard-dialog-search-results .ant-list-item:active {
  background-color: rgba(102, 136, 153, 0.05);
}
.ant-list.add-to-dashboard-dialog-selection .ant-list-item {
  padding: 12px;
}
.ant-list.add-to-dashboard-dialog-selection .ant-list-item .add-to-dashboard-dialog-item-content {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.ant-list.add-to-dashboard-dialog-selection .ant-list-item .ant-list-item-action li {
  margin: 0;
  padding: 0;
}
