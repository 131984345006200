.public-dashboard-page {
  width: 100%;
}
.public-dashboard-page .page-header-wrapper {
  margin-top: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.public-dashboard-page > .container {
  min-height: calc(100vh - 95px);
}
.public-dashboard-page .loading-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.public-dashboard-page #footer {
  height: 95px;
  text-align: center;
}
