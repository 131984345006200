.query-editor-container {
  margin-bottom: 0;
  position: relative;
}
.query-editor-container .ace_editor {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}
