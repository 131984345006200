.alert-destinations {
  position: relative;
}
.alert-destinations ul {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}
.alert-destinations ul li {
  color: rgba(0, 0, 0, 0.85);
  height: 46px;
  border-bottom: 1px solid #e8e8e8;
}
.alert-destinations ul li .remove-button {
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.alert-destinations ul li .toggle-button {
  margin: 0 7px;
}
.alert-destinations ul li .destination-warning {
  color: #f5222d;
}
.alert-destinations ul li .destination-warning:last-child {
  margin-right: 14px;
}
.alert-destinations .add-button {
  position: absolute;
  right: 0;
  top: -33px;
}
.destination-wrapper {
  padding-left: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 38px;
  width: 100%;
}
.select-items-dialog .destination-wrapper {
  padding: 0;
}
.destination-wrapper .destination-icon {
  height: 25px;
  width: 25px;
  margin: 2px 5px 0 0;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}
.destination-wrapper .destination-icon.fa {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 12px;
}
.select-items-dialog .destination-wrapper .destination-icon {
  width: 35px;
  height: 35px;
}
