.empty-state {
  width: 100%;
  margin: 0 auto 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
}
.empty-state .empty-state__summary,
.empty-state .empty-state__steps {
  width: 48%;
  padding: 35px;
  padding-bottom: 25px;
}
.empty-state .empty-state__steps {
  padding-left: 0;
}
.empty-state .empty-state__summary {
  -ms-flex-item-align: start;
      align-self: flex-start;
  text-align: center;
  background: rgba(102, 136, 153, 0.025);
}
.empty-state .empty-state__summary p {
  margin-bottom: 0;
}
.empty-state ol {
  margin-bottom: 15px;
  padding: 17px;
}
.empty-state li.done {
  text-decoration: line-through;
}
.empty-state h2 {
  margin: 0 0 15px;
}
.empty-state h4 {
  margin-top: 0;
  margin-bottom: 15px;
}
.empty-state a:hover {
  cursor: pointer;
}
@media (max-width: 767px) {
  .empty-state {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .empty-state .empty-state__summary {
    margin-bottom: 25px;
    padding-bottom: 15px;
  }
  .empty-state .empty-state__summary,
  .empty-state .empty-state__steps {
    width: 100%;
  }
  .empty-state .empty-state__steps {
    padding-left: 35px;
    padding-top: 15px;
  }
}
.empty-state-wrapper {
  position: relative;
}
.empty-state-wrapper .close-button {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  -webkit-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}
.empty-state-wrapper .close-button:hover,
.empty-state-wrapper .close-button:focus {
  color: #595959;
}
.empty-state-wrapper .close-button:active {
  -webkit-filter: contrast(200%);
          filter: contrast(200%);
}
