.snippet-content {
  max-width: 500px;
  max-height: 56px;
  overflow: hidden;
  white-space: pre-wrap;
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.query-snippets-table table {
  height: 1px;
}
.query-snippets-table .ant-table-row {
  height: 100%;
}
.query-snippets-table .ant-table-cell {
  height: 100%;
}
.query-snippets-table .ant-table-cell > .table-main-title {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  width: 100%;
}
