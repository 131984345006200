.query-api-key-dialog-wrapper .ant-input-group.ant-input-group-compact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.query-api-key-dialog-wrapper .ant-input-group.ant-input-group-compact .ant-input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
}
.query-api-key-dialog-wrapper .ant-input-group.ant-input-group-compact .ant-btn {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  height: auto;
}
