.widget-wrapper .widget-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.widget-wrapper .widget-actions .action {
  font-size: 24px;
  cursor: pointer;
  line-height: 100%;
  display: block;
  padding: 4px 10px 3px;
}
.widget-wrapper .widget-actions .action:focus {
  background-color: rgba(0, 0, 0, 0.1);
}
.widget-wrapper .widget-actions .action:hover {
  background-color: transparent;
  color: #2196F3;
}
.widget-wrapper .widget-actions .action:active {
  -webkit-filter: brightness(75%);
          filter: brightness(75%);
}
.widget-wrapper .parameter-container {
  margin: 0 15px;
}
.widget-wrapper .body-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}
.widget-wrapper .body-container .body-row {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
}
.widget-wrapper .body-container .body-row-auto {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.widget-wrapper .spinner-container {
  position: relative;
}
.widget-wrapper .spinner-container .spinner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.widget-wrapper .scrollbox:empty {
  padding: 0 !important;
  font-size: 1px !important;
}
.widget-wrapper .widget-text :first-child {
  margin-top: 0;
}
.widget-wrapper .widget-text :last-child {
  margin-bottom: 0;
}
.editing-mode .widget-menu-remove {
  display: block;
}
.editing-mode .query-link {
  pointer-events: none;
  cursor: move;
}
.editing-mode .th-title {
  cursor: move;
}
.editing-mode .refresh-indicator {
  -webkit-transition-duration: 0s;
       -o-transition-duration: 0s;
          transition-duration: 0s;
  font-size: 13px;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
  color: #bbbbbb;
  -webkit-transform: translateY(-4px);
      -ms-transform: translateY(-4px);
          transform: translateY(-4px);
}
.editing-mode .refresh-indicator .rd-timer {
  display: none;
}
.editing-mode .refresh-indicator .refresh-icon:before {
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
  opacity: 0;
}
.editing-mode .refresh-indicator .rd-timer {
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
  opacity: 1;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}
.refresh-indicator {
  font-size: 18px;
  color: #86a1af;
  -webkit-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
  -webkit-transition-delay: 150ms;
       -o-transition-delay: 150ms;
          transition-delay: 150ms;
  -webkit-transform: translateX(22px);
      -ms-transform: translateX(22px);
          transform: translateX(22px);
  position: absolute;
  right: 29px;
  top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.refresh-indicator .refresh-icon {
  position: relative;
}
.refresh-indicator .refresh-icon:before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0;
  width: 24px;
  height: 24px;
  background-color: #e8ecf0;
  border-radius: 50%;
  -webkit-transition: opacity 100ms linear;
  -o-transition: opacity 100ms linear;
  transition: opacity 100ms linear;
  -webkit-transition-delay: 150ms;
       -o-transition-delay: 150ms;
          transition-delay: 150ms;
}
.refresh-indicator .refresh-icon i {
  height: 24px;
  width: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.refresh-indicator .rd-timer {
  font-size: 13px;
  display: inline-block;
  font-variant-numeric: tabular-nums;
  opacity: 0;
  -webkit-transform: translateX(-6px);
      -ms-transform: translateX(-6px);
          transform: translateX(-6px);
  -webkit-transition: all 100ms linear;
  -o-transition: all 100ms linear;
  transition: all 100ms linear;
  -webkit-transition-delay: 150ms;
       -o-transition-delay: 150ms;
          transition-delay: 150ms;
  color: #bbbbbb;
  background-color: rgba(255, 255, 255, 0.9);
  padding-left: 2px;
  padding-right: 1px;
  margin-right: -4px;
  margin-top: 2px;
}
.widget-visualization[data-refreshing="false"] .refresh-indicator {
  display: none;
}
.tile .widget-menu-regular,
.tile .btn__refresh {
  opacity: 0 !important;
  -webkit-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
.tile .t-header .th-title {
  padding-right: 23px;
}
.tile .t-header .th-title .hidden-print {
  margin-bottom: 0;
}
.tile .t-header .th-title .query-link {
  color: rgba(0, 0, 0, 0.8);
  font-size: 15px;
  font-weight: 500;
}
.tile .t-header .th-title .query-link:not(.visualization-name) {
  color: rgba(0, 0, 0, 0.5);
}
.tile .t-header .query--description {
  font-size: 14px;
  line-height: 1.5;
  font-style: italic;
}
.tile .t-header .query--description p {
  margin-bottom: 0;
}
.tile .t-header.widget {
  padding: 15px;
}
.tile:hover .widget-menu-regular,
.tile:focus .widget-menu-regular,
.tile:active .widget-menu-regular,
.tile:focus-within .widget-menu-regular,
.tile:hover .btn__refresh,
.tile:focus .btn__refresh,
.tile:active .btn__refresh,
.tile:focus-within .btn__refresh {
  opacity: 1 !important;
  -webkit-transition: opacity 0.35s ease-in-out;
  -o-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
.tile:hover .refresh-indicator,
.tile:focus .refresh-indicator,
.tile:active .refresh-indicator,
.tile:focus-within .refresh-indicator {
  font-size: 13px;
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
  color: #bbbbbb;
  -webkit-transform: translateY(-4px);
      -ms-transform: translateY(-4px);
          transform: translateY(-4px);
}
.tile:hover .refresh-indicator .refresh-icon:before,
.tile:focus .refresh-indicator .refresh-icon:before,
.tile:active .refresh-indicator .refresh-icon:before,
.tile:focus-within .refresh-indicator .refresh-icon:before {
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
  opacity: 0;
}
.tile:hover .refresh-indicator .rd-timer,
.tile:focus .refresh-indicator .rd-timer,
.tile:active .refresh-indicator .rd-timer,
.tile:focus-within .refresh-indicator .rd-timer {
  -webkit-transition-delay: 0s;
       -o-transition-delay: 0s;
          transition-delay: 0s;
  opacity: 1;
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}
.tile .tile__bottom-control {
  padding: 10px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tile .tile__bottom-control .btn-transparent:first-child {
  margin-left: -10px;
}
.tile .tile__bottom-control .btn-transparent:last-child {
  margin-right: -10px;
}
.tile .tile__bottom-control a,
.tile .tile__bottom-control .plain-button {
  color: rgba(0, 0, 0, 0.65);
}
.tile .tile__bottom-control a:hover,
.tile .tile__bottom-control .plain-button:hover,
.tile .tile__bottom-control a:focus,
.tile .tile__bottom-control .plain-button:focus {
  color: rgba(0, 0, 0, 0.95);
}
