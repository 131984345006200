.query-page-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  margin-top: 10px;
}
.query-page-header > div {
  padding: 5px 0;
}
.query-page-header .title-with-tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  margin: -5px 0;
}
.query-page-header .title-with-tags > div {
  padding: 5px 0;
}
.query-page-header .title-with-tags .page-title h3 {
  margin: 0 10px 0 0 !important;
}
@media (max-width: 767px) {
  .query-page-header .title-with-tags .page-title h3 {
    font-size: 18px;
  }
}
.query-page-header .query-tags {
  display: inline-block;
  vertical-align: middle;
}
.query-page-header .tags-control > .label-tag {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.query-page-header:hover .tags-control > .label-tag,
.query-page-header:focus .tags-control > .label-tag,
.query-page-header:active .tags-control > .label-tag,
.query-page-header:focus-within .tags-control > .label-tag {
  opacity: 1;
}
.query-page-header .header-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
@media (max-width: 515px) {
  .query-page-header .header-actions {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
  }
}
