.plain-button {
  all: unset;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-dropdown-menu-item > .plain-button {
  width: 100%;
  margin: -5px -12px;
  padding: 5px 12px;
}
.ant-menu-item > .plain-button {
  width: 100%;
  margin: 0 -16px;
  padding: 0 16px;
}
.plain-button-link {
  color: #2196f3;
  background: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.plain-button-link > a:only-child {
  color: currentColor;
}
.plain-button-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.plain-button-link:hover,
.plain-button-link:focus {
  color: #4db5ff;
  background: transparent;
  border-color: #4db5ff;
}
.plain-button-link:hover > a:only-child,
.plain-button-link:focus > a:only-child {
  color: currentColor;
}
.plain-button-link:hover > a:only-child::after,
.plain-button-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.plain-button-link:active {
  color: #1272cc;
  background: transparent;
  border-color: #1272cc;
}
.plain-button-link:active > a:only-child {
  color: currentColor;
}
.plain-button-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.plain-button-link[disabled],
.plain-button-link[disabled]:hover,
.plain-button-link[disabled]:focus,
.plain-button-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #e8e8e8;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.plain-button-link[disabled] > a:only-child,
.plain-button-link[disabled]:hover > a:only-child,
.plain-button-link[disabled]:focus > a:only-child,
.plain-button-link[disabled]:active > a:only-child {
  color: currentColor;
}
.plain-button-link[disabled] > a:only-child::after,
.plain-button-link[disabled]:hover > a:only-child::after,
.plain-button-link[disabled]:focus > a:only-child::after,
.plain-button-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.plain-button-link:hover {
  background: transparent;
}
.plain-button-link:hover,
.plain-button-link:focus,
.plain-button-link:active {
  border-color: transparent;
}
.plain-button-link[disabled],
.plain-button-link[disabled]:hover,
.plain-button-link[disabled]:focus,
.plain-button-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.plain-button-link[disabled] > a:only-child,
.plain-button-link[disabled]:hover > a:only-child,
.plain-button-link[disabled]:focus > a:only-child,
.plain-button-link[disabled]:active > a:only-child {
  color: currentColor;
}
.plain-button-link[disabled] > a:only-child::after,
.plain-button-link[disabled]:hover > a:only-child::after,
.plain-button-link[disabled]:focus > a:only-child::after,
.plain-button-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
