.dynamic-form .extra-options {
  margin: 25px 0 10px;
}
.dynamic-form .extra-options-button,
.dynamic-form .extra-options-button:focus,
.dynamic-form .extra-options-button:hover {
  height: 40px;
  font-weight: 500;
  background-color: rgba(102, 136, 153, 0.1);
  border-color: rgba(102, 136, 153, 0.15);
  color: #595959;
}
.dynamic-form .extra-options-button:focus,
.dynamic-form .extra-options-button:hover {
  background-color: rgba(102, 136, 153, 0.15);
}
.dynamic-form .extra-options-content {
  margin-top: 15px;
}
.dynamic-form .extra-options-content .ant-form-item:last-of-type {
  margin-bottom: 0 !important;
}
