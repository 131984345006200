.query-fullscreen .query-editor-wrapper {
  padding: 15px;
  margin-bottom: 10px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
}
.query-fullscreen .query-editor-wrapper .query-editor-container {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.query-fullscreen .query-editor-wrapper .query-editor-container[data-executing] .ace_marker-layer .ace_selection {
  background-color: #ffd2b5;
}
.query-fullscreen .query-editor-wrapper .query-editor-controls {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  margin-top: 10px;
}
.query-fullscreen .query-page-query-description {
  border-top: 1px solid #efefef;
  padding: 0 15px 0 0;
}
.query-fullscreen .query-page-query-description .edit-in-place {
  display: block;
  max-height: 150px;
  overflow: auto;
  padding: 15px 5px 15px 15px;
}
.query-fullscreen .query-page-query-description .edit-in-place.active {
  overflow: visible;
  max-height: unset !important;
}
.query-fullscreen .query-page-query-description .edit-in-place.active .ant-input {
  resize: vertical;
  height: 30vh;
}
.query-fullscreen .query-results-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 15px 0 15px 0;
}
.query-fullscreen .query-results-wrapper .query-parameters-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.query-fullscreen .query-results-wrapper .query-alerts {
  margin: 15px 0;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.query-fullscreen .query-results-wrapper .query-results-log {
  padding: 10px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.query-fullscreen .query-results-wrapper .ant-tabs {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.query-fullscreen .query-results-wrapper .ant-tabs .ant-tabs-bar {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.query-fullscreen .query-results-wrapper .ant-tabs .ant-tabs-content-holder {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  position: relative;
}
@media (min-width: 880px) {
  .query-fullscreen .query-results-wrapper .ant-tabs .ant-tabs-content-holder .ant-tabs-tabpane {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }
}
