@font-face {
  font-family: "redash-icons";
  src: url("fonts/redash-icons.eot?ehpufm");
  src: url("fonts/redash-icons.eot?ehpufm#iefix") format("embedded-opentype"), url("fonts/redash-icons.ttf?ehpufm") format("truetype"), url("fonts/redash-icons.woff?ehpufm") format("woff"), url("fonts/redash-icons.svg?ehpufm#redash-icons") format("svg");
  font-weight: normal;
  font-style: normal;
}
i.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "redash-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-flash-off:before {
  content: "\e900";
}
.icon-flash:before {
  content: "\e901";
}
