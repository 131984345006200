.dashboard-wrapper {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-bottom: 70px;
}
.dashboard-wrapper .layout {
  margin: -15px -15px 0;
}
.dashboard-wrapper .tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.dashboard-wrapper .pivot-table-visualization-container > table,
.dashboard-wrapper .visualization-renderer > .visualization-renderer-wrapper {
  overflow: visible;
}
.dashboard-wrapper.preview-mode .widget-menu-regular {
  display: block;
}
.dashboard-wrapper.preview-mode .widget-menu-remove {
  display: none;
}
.dashboard-wrapper.editing-mode {
  /* Y axis lines */
  background: -o-linear-gradient(left, transparent, transparent 1px, #f6f8f9 1px, #f6f8f9), -o-linear-gradient(top, #b3babf, #b3babf 1px, transparent 1px, transparent);
  background: linear-gradient(to right, transparent, transparent 1px, #f6f8f9 1px, #f6f8f9), linear-gradient(to bottom, #b3babf, #b3babf 1px, transparent 1px, transparent);
  background-size: 5px 50px;
  background-position-y: -8px;
  /* X axis lines */
}
.dashboard-wrapper.editing-mode::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 85px;
  right: 0;
  background: -o-linear-gradient(top, transparent, transparent 2px, #f6f8f9 2px, #f6f8f9 5px), -o-linear-gradient(right, #b3babf, #b3babf 1px, transparent 1px, transparent);
  background: linear-gradient(to bottom, transparent, transparent 2px, #f6f8f9 2px, #f6f8f9 5px), linear-gradient(to left, #b3babf, #b3babf 1px, transparent 1px, transparent);
  background-size: calc((100% + 15px) / 6) 5px;
  background-position: -7px 1px;
}
.dashboard-wrapper .widget-auto-height-enabled .spinner {
  position: static;
}
.dashboard-wrapper .widget-auto-height-enabled .scrollbox {
  overflow-y: hidden;
}
.react-grid-layout.disable-animations > .react-grid-item {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .visualization-renderer,
.query-fixed-layout .visualization-renderer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .visualization-renderer > .visualization-renderer-wrapper,
.query-fixed-layout .visualization-renderer > .visualization-renderer-wrapper {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  position: relative;
}
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .visualization-renderer > .filters-wrapper,
.query-fixed-layout .visualization-renderer > .filters-wrapper {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .sunburst-visualization-container,
.query-fixed-layout .sunburst-visualization-container,
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .sankey-visualization-container,
.query-fixed-layout .sankey-visualization-container,
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .map-visualization-container,
.query-fixed-layout .map-visualization-container,
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .word-cloud-visualization-container,
.query-fixed-layout .word-cloud-visualization-container,
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .box-plot-deprecated-visualization-container,
.query-fixed-layout .box-plot-deprecated-visualization-container,
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .chart-visualization-container,
.query-fixed-layout .chart-visualization-container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  overflow: hidden;
}
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .counter-visualization-container,
.query-fixed-layout .counter-visualization-container {
  height: 100%;
}
.dashboard-wrapper .dashboard-widget-wrapper:not(.widget-auto-height-enabled) .counter-visualization-container .counter-visualization-content,
.query-fixed-layout .counter-visualization-container .counter-visualization-content {
  position: absolute;
  left: 10px;
  top: 15px;
  right: 10px;
  bottom: 15px;
  height: auto;
  overflow: hidden;
  padding: 0;
}
.query-fixed-layout .visualization-renderer > .visualization-renderer-wrapper .counter-visualization-container {
  max-width: 600px;
  max-height: 400px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.react-grid-item {
  -ms-touch-action: initial !important;
      touch-action: initial !important;
}
.react-grid-item.react-draggable {
  -ms-touch-action: none !important;
      touch-action: none !important;
}
.react-grid-item.react-grid-placeholder {
  border-radius: 3px;
  background-color: #e0e6eb;
  opacity: 0.5;
}
.react-grid-item.resizing {
  z-index: 3;
}
.react-grid-item.cssTransforms:not(.resizing) {
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}
.react-grid-item > .react-resizable-handle {
  background: none;
}
.react-grid-item > .react-resizable-handle:after {
  width: 11px;
  height: 11px;
  right: 5px;
  bottom: 5px;
}
