.databricks-schema-browser .schema-control .database-select-open .ant-input-group-addon {
  background-color: #fff;
}
.databricks-schema-browser .schema-control .database-select-open .ant-input-group-addon .ant-select-selection-item {
  visibility: hidden;
}
.databricks-schema-browser .schema-control .ant-input-wrapper {
  table-layout: fixed;
}
.databricks-schema-browser .schema-control .ant-input-wrapper .ant-input-group-addon {
  width: 40%;
  padding: 0;
  border-bottom-left-radius: 0;
}
.databricks-schema-browser .schema-control .ant-input-wrapper .ant-input-group-addon .ant-select {
  width: 100%;
}
.databricks-schema-browser .schema-control .ant-input-wrapper .ant-input-group-addon .ant-select .ant-select-selection-item {
  text-align: left;
}
.databricks-schema-browser .schema-control .ant-input-wrapper .ant-input-group-addon .ant-select.ant-select-focused .ant-select-selector {
  color: inherit;
}
.databricks-schema-browser .schema-control .ant-input-wrapper .ant-input {
  border-bottom-right-radius: 0;
}
.databricks-schema-browser .schema-list-wrapper {
  position: relative;
  height: 100%;
  border: 1px solid #eaeaea;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  margin-bottom: 20px;
  padding-bottom: 32px;
}
.databricks-schema-browser .schema-list-wrapper .load-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.databricks-schema-browser .schema-list-wrapper .load-button .ant-btn {
  color: #595959;
  padding: 0 10px;
}
.databricks-schema-browser-db-dropdown {
  width: 50vw !important;
}
