page-query-view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.query-page-wrapper {
  width: 100%;
}
.query-page-wrapper .query-view-content .query-results {
  margin: 0px 15px 0px;
}
.query-page-wrapper .query-view-content .query-results .query-results-footer {
  position: relative;
}
.query-page-wrapper .query-view-content .query-results .query-results-footer .query-execution-status {
  position: fixed;
  min-width: 250px;
  bottom: 0;
  right: 0;
  padding: 15px;
  z-index: 1000;
}
@media (min-width: 768px) {
  .query-page-wrapper .query-view-content .query-results .query-results-footer .query-execution-status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100%;
    position: absolute;
    padding: 3px;
  }
  .query-page-wrapper .query-view-content .query-results .query-results-footer .query-execution-status .ant-alert {
    -webkit-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
  }
}
.query-page-wrapper .query-view-content .query-results .query-execution-metadata {
  border: 1px solid #d9d9d9;
  border-top-width: 0px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 0px 0px 4px 4px;
  background: white;
}
.query-page-wrapper .query-view-content .query-results .query-parameters-wrapper {
  margin: 15px 0 5px 0;
}
.query-page-wrapper .query-view-content .query-results .query-alerts {
  margin: 15px 0;
}
.query-page-wrapper .query-view-content .query-results .query-results-log {
  padding: 10px;
}
.query-page-wrapper.query-fixed-layout .query-view-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.query-page-wrapper.query-fixed-layout .query-view-content .query-results {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  overflow: auto;
}
.query-page-wrapper.query-fixed-layout .query-view-content .query-parameters-wrapper {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.query-page-wrapper.query-fixed-layout .query-view-content .query-alerts {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.query-page-wrapper.query-fixed-layout .query-view-content .query-results-log {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.query-page-wrapper.query-fixed-layout .query-view-content .ant-tabs {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.query-page-wrapper.query-fixed-layout .query-view-content .ant-tabs .ant-tabs-bar {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
}
.query-page-wrapper.query-fixed-layout .query-view-content .ant-tabs .ant-tabs-content-holder {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  position: relative;
}
.query-page-wrapper.query-fixed-layout .query-view-content .ant-tabs .ant-tabs-content-holder .ant-tabs-tabpane {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
