.query-source-alerts {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.45);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-top: 10vh;
}
.query-source-alerts .ant-card {
  width: 50%;
  min-width: 300px;
  height: auto;
}
.query-source-alerts .ant-card-body .query-source-alerts-icon {
  font-size: 60px;
  line-height: 60px;
  margin: 20px 0;
  text-align: center;
  color: #FF9800;
}
.query-source-alerts .ant-card-body h4 {
  text-align: center;
  margin: 20px 0;
  font-weight: normal;
}
.query-source-alerts .ant-card-body p {
  text-align: center;
  margin: 10px 0;
  font-size: 1.1em;
}
.query-source-alerts .ant-card-body .query-source-alerts-actions {
  text-align: center;
  margin: 20px 0;
}
.query-source-alerts .ant-card-body .query-source-alerts-actions .ant-btn {
  margin: 0 15px 0 0;
}
.query-source-alerts .ant-card-body .query-source-alerts-actions .ant-btn:last-child {
  margin-right: 0;
}
.query-source-alerts .ant-card-body :first-child {
  margin-top: 0;
}
.query-source-alerts .ant-card-body :last-child {
  margin-bottom: 0;
}
