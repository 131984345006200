.tags-list .tags-list-title {
  margin: 15px 5px 5px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.tags-list .tags-list-title .tags-list-label {
  display: block;
  white-space: nowrap;
  margin: 0;
}
.tags-list .tags-list-title a,
.tags-list .tags-list-title .plain-button {
  display: block;
  white-space: nowrap;
  cursor: pointer;
}
.tags-list .tags-list-title a .anticon,
.tags-list .tags-list-title .plain-button .anticon {
  font-size: 75%;
  margin-right: 2px;
}
.tags-list .ant-badge-count {
  background-color: rgba(102, 136, 153, 0.1);
  color: rgba(102, 136, 153, 0.75);
}
.tags-list .ant-menu.ant-menu-inline {
  border: none;
}
.tags-list .ant-menu.ant-menu-inline .ant-menu-item {
  width: 100%;
}
.tags-list .ant-menu.ant-menu-inline .ant-menu-item-selected .ant-badge-count {
  background-color: #2196f3;
  color: white;
}
.tags-list .ant-menu.ant-menu-inline .ant-menu-item:hover,
.tags-list .ant-menu.ant-menu-inline .ant-menu-item:active,
.tags-list .ant-menu.ant-menu-inline .ant-menu-item:focus,
.tags-list .ant-menu.ant-menu-inline .ant-menu-item:focus-within {
  color: #2196f3;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
